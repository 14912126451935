"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Styles = void 0;
//@ts-nocheck
var Styles = {
  typography: {
    body: {
      fontSize: 17,
      lineHeight: 20
    },
    callout: {
      fontSize: 16,
      lineHeight: 20
    },
    caption: {
      fontSize: 11,
      lineHeight: 13
    },
    footnote: {
      fontSize: 13,
      lineHeight: 18,
      color: "#999999"
    },
    headline: {
      fontSize: 17,
      lineHeight: 22
    },
    subhead: {
      fontSize: 15,
      lineHeight: 20
    },
    title1: {
      fontSize: 34,
      lineHeight: 41
    },
    title2: {
      fontSize: 28,
      lineHeight: 34
    },
    title3: {
      fontSize: 22,
      lineHeight: 26
    }
  }
};
exports.Styles = Styles;