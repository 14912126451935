'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

require('rsuite/dist/styles/rsuite-default.css');
var React = _interopDefault(require('react'));
var FlexboxGrid = _interopDefault(require('rsuite/lib/FlexboxGrid'));

function deleteKeys(obj, keys) {
    if (obj && keys && Array.isArray(keys)) {
        keys.forEach((key) => {
            delete obj[key];
        });
    }
}
const delete_keys = [
    'children'
];
const FBGridV = (props) => {
    let _gridProps = Object.assign({}, props);
    deleteKeys(_gridProps, delete_keys);
    return React.createElement(FlexboxGrid, Object.assign({}, _gridProps), props.children);
};
const FBItemV = (props) => {
    let _gridProps = Object.assign({}, props);
    deleteKeys(_gridProps, delete_keys);
    return React.createElement(FlexboxGrid.Item, Object.assign({}, _gridProps), props.children);
};

exports.FBGridV = FBGridV;
exports.FBItemV = FBItemV;
